footer {
    background-color: #f8f9fa;
    padding: 20px 0;
    text-align: center;
    /* position: fixed; */
    bottom: 0;
    width: 100%;
    position: relative;  /* Ensure content is not affected by the popup */
  }
  
  footer p {
    margin: 0;
    font-size: 14px;
    color: #666;
  }
  