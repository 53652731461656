/* Data Extraction Container */
.data-extraction-container {
  padding: 2rem 2rem;
  max-width: 80%;
  margin: auto;
  background: linear-gradient(to right, #f9f9f9, #ffffff);
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin-top: 4%;
}

/* Header Section */
.data-extraction-header {
  text-align: center;
  margin-bottom: 4rem;
}

.data-extraction-header h2 {
  color: #007BFF;
  margin: 0;
  margin-bottom: 0.5rem;
}

.data-extraction-header p {
  font-size: 1.2rem;
  color: #6c757d;
  max-width: 100%;
  margin: 0 auto;
  line-height: 1.8;
}

/* Content Section - Cards */
.data-extraction-content {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.data-extraction-item {
  flex: 1 1 calc(50% - 2rem);
  /* Two columns with gap */
  padding: 2.5rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.data-extraction-item:hover {
  transform: translateY(-6px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.data-extraction-item h3 {
  color: #343a40;
  margin-bottom: 1rem;
}

.data-extraction-item p {
  font-size: 1rem;
  color: #6c757d;
  line-height: 1.6;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .data-extraction-item {
    flex: 1 1 100%;
    /* Single column layout on smaller screens */
  }
  .data-extraction-container {
    padding: 4rem 2rem;
  }
  
}