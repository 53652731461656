/* Default CSS rules for large screens */
.slideshow-container .slideshow-slide .slideshow-image-container img {
  width: 100%;
  height: 40vh; /* Default height for larger screens */
  object-fit: cover;
}

/* Media query for smaller screens */
@media (max-width: 600px) { 
  /* When screen width is 600px or less */
  .slideshow-container .slideshow-slide .slideshow-image-container img {
    width: 100%;
    height: 16vh; /* Smaller height for smaller screens */
    object-fit: cover;
  }
}

.slideshow-container.slideshow-image-container{
  height: 55vh;
}
