.scripting-container {
    background: linear-gradient(135deg, #f0f4f8, #d9e4f0);
    padding: 7%;
    background-color: #f4f7fb; /* Light, clean background */
    text-align: center;
    /* margin-top: 4%; */
    overflow: hidden;
}

.scripting-content {
    /* max-width: 1200px; */
    margin: 0 auto;
    color: #333;
    /* margin: 4%; */
}

.section-title-script {
    /* font-size: 2.5rem; */
    color: #0056b3;
    margin-bottom: 20px;
    font-weight: 700;
    animation: fadeIn 1s ease-in-out;
}

@media (max-width: 768px) {
    .section-title-script {
        font-size: 2rem;
        margin-top: 12%;
    }
}

.section-text-script {
    /* font-size: 1.4rem; */
    color: #555;
    margin-bottom: 40px;
    line-height: 1.8;
    animation: fadeIn 1.5s ease-in-out;
}

@media (max-width: 768px) {
    .section-text-script {
        font-size: 1.2rem;
    }
}

.scripting-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-bottom: 50px;
}

@media (max-width: 768px) {
    .scripting-main {
        flex-direction: column;
        gap: 20px;
    }
}

.section-image-script {
    width: 50%;
    max-width: 500px;
    border-radius: 12px;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
}

@media (max-width: 768px) {
    .section-image-script {
        width: 100%;
    }
}

.scripting-details {
    text-align: left;
    max-width: 90vh;
}

@media (max-width: 768px) {
    .scripting-details {
        text-align: center;
    }
}

.scripting-subtitle {
    /* font-size: 2.4rem; */
    color: #444;
    margin-bottom: 20px;
    font-weight: 600;
    animation: fadeIn 1.2s ease-in-out;
}

.scripting-description {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 20px;
    animation: fadeIn 1.3s ease-in-out;
}

.scripting-list {
    list-style-type: none;
    padding: 0;
}

.feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 1rem;
    animation: slideIn 1s ease-in-out;
}

.feature-icon {
    width: 40px;
    margin-right: 10px;
}

.feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 40px;
    animation: fadeIn 2s ease-in-out;
}

.feature-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease-in-out;
}

.feature-card:hover {
    transform: translateY(-10px);
}

.feature-image {
    width: 100px;
    margin-bottom: 10px;
    border-radius: 50%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(-50%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}
.scripting-footer {
    background-color: #f9f9f9; /* Light background for contrast */
    padding: 20px;
    border-radius: 10px;
    margin-top: 40px;
}

.scripting-footer-title {
    font-size: 1.8em;
    color: #333; /* Darker text color for better readability */
    margin-bottom: 15px;
    text-align: center; /* Center align the title */
}

.scripting-footer-text {
    font-size: 1.1em;
    color: #666;
    line-height: 1.6;
    text-align: left; /* Left align for better readability */
    margin-bottom: 10px;
}

.scripting-footer-text strong {
    font-weight: bold;
    color: #007bff; /* Highlight key points with blue color */
}

.scripting-footer-text br {
    margin-bottom: 15px; /* Add spacing between each key point */
}
.learn-more-button {
    display: inline-block;
    background-color: #00d4ff;
    color: white;
    padding: 12px 30px;
    /* font-size: 1.1rem; */
    border-radius: 30px;
    margin-top: 30px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.learn-more-button:hover {
    background-color: #007bff;
    transform: scale(1.05);
}