/* .clients-page {
    padding: 40px 0;
  } */
  
  .section-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .section-description {
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  .client-card {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .client-card:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .card-text {
    font-size: 14px;
    color: #666;
  }
  

  .clients-page {
    padding: 20px;
  }
  
  .section-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .section-description {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .client-card {
    height: 11vh; /* Adjust this value to set the height you want */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 10px 20px rgba(94, 255, 0, 0.356); 
    /* box-shadow: 1.56em 1.56em 0px 0px rgb(180,212,79); */
  }
  
  .client-card img {
    height: 10vh;
    object-fit: contain; /* Ensure the image fits within the specified height */
    margin-bottom: 10px;
  }
  
  .client-card .card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .client-card .card-title {
    margin-bottom: 10px;
  }
  