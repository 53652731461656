@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}

.data-processing-container {
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  padding: 20px;
  border-radius: 10px;
}

.data-image {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.list-group-item-hover:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.dataservice {
  display: flex;
  align-items: center;
  margin-bottom: 5%;
  opacity: 0; /* Initial state for fade-in animation */
  animation: fadeIn 0.6s forwards; /* Apply fade-in animation */
}

.dataservice:nth-child(even) {
  flex-direction: row-reverse; /* Alternate layout for even dataservices */
}

.dataservice-image {
  width: 150px;
  height: auto;
  margin: 0 20px; /* Adjust margin for even/odd children */
  border-radius: 10px;
}

.dataservice-text {
  flex: 1;
}

.card {
  width: 18rem;
  margin: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-img-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-body {
  text-align: center;
}

.shadow-sm {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.shadow-lg {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Initial offset for the animation */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Final position */
  }
}
