.cloud-data-migration {
    padding: 2rem 2rem;
    max-width: 80%;
    margin: auto;
    background: linear-gradient(to right, #f2f8f5, #ffffff);
    border-radius: 8px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    margin-top: 4%;
  }
  
  .cloud-data-intro {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .cloud-data-intro h2 {
    color: #007BFF;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  
  .cloud-data-intro p {
    font-size: 1.25rem;
    color: #666;
    max-width: 100%;
    margin: 0 auto;
  }
  
  .cloud-data-details {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .cloud-data-point {
    flex: 1 1 calc(50% - 2rem); /* Two columns with gap */
    padding: 2rem;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .cloud-data-point:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .cloud-data-point h3 {
    color: #343a40;
    margin-bottom: 1rem;
  }
  
  .cloud-data-point p {
    font-size: 1rem;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .cloud-data-point {
      flex: 1 1 100%; /* Single column on mobile */
    }
    .cloud-data-migration {
      padding: 4rem 2rem;
    }
  }
      