.charting-container {
    background-color: #f0f4f8; /* Light background for contrast */
    padding: 40px 20px;
    text-align: center;
    margin-top: 4%;
}

.charting-header {
    margin-bottom: 30px;
}

.section-title {
    font-size: 3.5rem;
    color: #ffffff;
    background: linear-gradient(135deg, #007bff, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 20px;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    /* position: relative; */
}

.section-title::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 8px;
    /* background-color: #28a745; */
    transform: translateY(-50%);
    z-index: -1;
}

.section-charting-text {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #555;
    line-height: 1.7;
    max-width: 50%;
    margin: 0 auto;
}

.highlights-title {
    font-size: 2rem;
    font-weight: bold;
    color: #007bff; /* Bright color for emphasis */
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
}   
/* Highlights Cards */
.highlights-cards {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.highlight-card {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 300px;
    flex: 1;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #e0e0e0;
    margin: 10px;
}

.highlight-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.highlight-card-title {
    font-size: 1.6rem;
    color: #007bff;
    margin-bottom: 10px;
    /* font-weight: bold; */
}

.highlight-card-description {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.6;
}


.highlights-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.highlights-list li {
    font-size: 1.2rem;
    color: #333; /* Darker color for readability */
    margin-bottom: 15px;
    padding-left: 20px;
    position: relative;
}

.highlights-list li::before {
    position: absolute;
    left: 0;
    color: #28a745; /* Green checkmark */
    font-size: 1.4rem;
    top: 50%;
    transform: translateY(-50%);
}

.charting-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.chart-section {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #e0e0e0;
    margin: 0 auto;
    max-width: 80%; /* Center and constrain width */
}

.chart-section.reversed {
    flex-direction: row-reverse;
}

.chart-info {
    text-align: left;
    /* max-width: 600px; */
}

.chart-title {
    /* font-size: 2rem; */
    color: #007bff;
    margin-bottom: 12px;
    /* font-weight: bold; */
}

.chart-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.8;
    margin-bottom: 20px;
}

.chart-image {
    width: 100%;
    max-width: 400px;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.chart-image:hover {
    transform: scale(1.05);
}

/* Expanded Explanation Styles */
.expanded-explanation {
    margin-top: 30px;
    padding: 20px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: none; /* Hide by default */
}

.expanded-explanation.show {
    display: block; /* Show when expanded */
    width: 72%;
    margin-left: 14%;
    margin-bottom: 1%;
}

/* .expanded-explanation h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 15px;
} */

.expanded-explanation p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
}

.expanded-explanation h3 {
    font-size: 1.5rem;
    margin-top: 20px;
    color: #444;
}

/* Responsive Design for Mobile Devices */
@media (max-width: 768px) {
    .section-title {
        font-size: 2.5rem;
    }

    .section-charting-text, .chart-description {
        font-size: 1.1rem;
    }

    .chart-section {
        flex-direction: column; /* Stack vertically on medium screens */
        padding: 20px;
    }
    .chart-section.reversed {
        flex-direction: column-reverse;
        padding: 20px;
    }
    .chart-info {
        text-align: center; /* Center text on smaller screens */
        margin-bottom: 20px; /* Adjust bottom margin for better spacing */
    }

    .chart-image {
        max-width: 90%; /* Ensure images fit within screen */
        margin: 0 auto; /* Center images */
    }
}

@media (max-width: 480px) {
    .section-title {
        font-size: 1.8rem;
    }

    .section-charting-text, .chart-description {
        font-size: 1rem;
    }

    .chart-section {
        padding: 15px; /* Further reduce padding on smaller screens */
    }

    .chart-title {
        font-size: 1.6rem;
    }

    .chart-description {
        font-size: 0.9rem;
    }

    .chart-image {
        max-width: 100%; /* Ensure images fit within screen */
        margin: 0 auto; /* Center images */
    }
}

.learn-more-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.learn-more-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}
