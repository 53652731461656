/* ContactUsDetailed.css */

/* Basic Styling */
.contact-us-detailed {
    background-color: white;
  }
/* */
  
  /* Contact Image and Text Container */
  .contact-image-container {
    position: relative; 
    text-align: center;
    margin-top: 4%;
  }
  
  .contact-image {
    width: 100%; 
    height: 50vh; 
    object-fit: cover;
  }
  
  .contact-image-text {
    position: absolute; 
    top: 40%;
    left: 25%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1.5em;
    text-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); 
  }
  
  @media (max-width: 768px) {.contact-image-text {
    position: absolute; 
    top: 65%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1.5em;
    text-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); 
  }}
  
  /* Contact Info Styles */
  .contact-info {
    text-align: center;
    margin: 20px 0;
    /* background-color: #f6f6f5;  */
    padding: 15px; /* Padding for spacing */
  }
 
  
  /* Contact Form and Map */
  .contact-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
  }
  
  .contact-form {
    flex: 1;
    padding-right: 20px;
  }
  
  .map {
    flex: 1;
    margin-left: 5%;
  }
  
  .form-group-new {
    margin-bottom: 15px;
  }
  
  .form-group-new label {
    display: block;
    font-weight: bold;
  }
  
  .form-group-new input,
  .form-group-new textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  @media (max-width: 768px) {.form-group-new input,
  .form-group-new textarea {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}
  .form-group-new button {
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-group-new button:hover {
    background-color: #555;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .contact-content {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-form {
      padding-right: 0;
    }
  
    .contact-image {
      height: 30vh; 
    }
  }
  
  .map-form{
  flex-direction: column;
  align-items: center;
  }
  
  .btn{
    margin: 1%;
  }
/* Container Styling for Sections */
.section {
  position: relative;
  padding: 60px 20px;
  margin: 40px 0;
  overflow: hidden;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.section:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

/* Decorative Background Elements */
.section::before, .section::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  background: rgba(0, 150, 255, 0.1);
  animation: float 6s ease-in-out infinite;
  z-index: -1;
}

.section::before {
  width: 200px;
  height: 200px;
  top: -50px;
  left: -50px;
}

.section::after {
  width: 300px;
  height: 300px;
  bottom: -80px;
  right: -80px;
  animation-delay: 3s;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(45deg);
  }
}

/* Heading Styles */
.section h3 {
  font-size: 2.5rem;
  color: #222;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}

.section h3::after {
  content: '';
  width: 60px;
  height: 4px;
  background: #0096ff;
  display: block;
  margin: 10px auto 0;
  border-radius: 2px;
}

/* Paragraph Styles */
.section p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  animation: fadeInUp 1s ease forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Button Style (Optional for Job Descriptions) */
.section .cta-button {
  display: inline-block;
  margin-top: 30px;
  padding: 15px 30px;
  background-color: #0096ff;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.section .cta-button:hover {
  background-color: #007acc;
}

/* Responsive Design */
@media (max-width: 768px) {
  .section {
    padding: 40px 15px;
  }

  .section h3 {
    font-size: 2rem;
  }

  .section p {
    font-size: 1rem;
  }
}
