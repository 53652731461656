/* src/components/CookieConsentPopup.css */

.cookie-consent-popup {
    position: fixed;
    bottom: 20px;
    background-color: #ffffff;
    padding: 30px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: auto;
    animation: slideIn 0.5s ease-in-out;
    width: 35vw;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

#h6 {
    text-align: justify;
}

.cookie-consent-content {
    font-family: 'Arial', sans-serif;
    color: #333;
}

.cookie-consent-content p {
    margin: 0;
    font-size: 15px;
    color: black;
    text-align: justify;
}

.cookie-consent-actions {
    justify-content: space-between;
    gap: 10px;
    margin-top: 4%;
}

.cookie-btn {
    padding: 10px 15px;
    border: 2px solid #047cfc; /* Border color for all buttons */
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease, transform 0.2s;
    width: 100%;
    margin-bottom: 1%;
}

/* First button (Accept All) */
.cookie-btn.accept {
    background-color: #047cfc; /* Background color for Accept All button */
    color: white;
}

/* Other buttons (Deny and Manage Preferences) */
.cookie-btn.deny,
.cookie-btn.manage {
    background-color: white; /* White background for other buttons */
    color: #047cfc; /* Text color to match the border */
}


.cookie-btn:active {
    transform: scale(1);
}

.cookie-btn.accept:hover {
    background-color: #0468b0; /* Slightly darker blue for hover */
}

.cookie-btn.deny:hover,
.cookie-btn.manage:hover {
    background-color: #f0f8ff; /* Light blue background on hover */
}

/* Responsive design for small screens (up to 480px) */
@media (max-width: 480px) {
    .cookie-consent-popup {
        /* right: 5%; */
        width: 90%; /* Ensure it's almost full width */
        padding: 20px;
        bottom: 10px;
    }
}

/* Responsive design for medium screens (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
    .cookie-consent-popup {
        /* right: 10%; */
        width: 80%; /* Adjust width for tablets */
        padding: 25px;
        bottom: 15px;
    }
}

/* Responsive design for large screens (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
    .cookie-consent-popup {
        /* right: 15%; */
        width: 60%; /* Adjust width for laptops */
        padding: 30px;
        bottom: 20px;
    }

}