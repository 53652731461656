/* Services.css */
.services-section {
  padding: 0px 0;
  text-align: center;
}

.services-content {
  /* max-width: 900px; */
  margin: 0 auto;
  background-color: black;
}

.services-content h3 {
  /* color: #333; */
  font-size: 28px;
  background-color: black;
}

.services-content ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center; /* Adjusted for better layout */
  flex-wrap: wrap; /* Adjusted for responsiveness */
  background-color: white;
}

.services-content li {
  width: 100%;
  object-fit: cover;
  margin: 2%;
}

@media (max-width: 480px){.services-content li {
  width: 100%;
  object-fit: cover;
  margin: 2%;
  flex-flow: wrap;
}
}

.service-container {
  /* height: 250px; */
  background-size: cover; /* Ensure the image covers the container */
  background-position: center; /* Center the background image */
  border-radius: 8px; /* Optional: Add border radius for each service */
  display: flex;
  align-items: flex-end; /* Align content to bottom of container */
  margin: 2%;
}

.service {
  width: 45%; /* Adjusted for better layout */
  padding: 20px; /* Optional: Add padding for each service */
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background for text */
  border-radius: 8px; /* Optional: Add border radius for each service */
}

.service h3 {
  /* color: #333; */
}

.service p {
  color: #666;
}
/* Services.css */
.services-section {
  padding: 7vh 0;
  text-align: center;
}

.services-content {
  /* max-width: 900px; */
  margin: 0 auto;
  width: 67%
}

.services-content h3 {
  color: white;
  font-size: 28px;
}

.services-content ul {
  list-style-type: none;
  padding: 0;
}

.service-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.service-container {
  /* height: 250px; */
  height: 39vh;
  width: 100vh; /* Adjusted for better layout */
  background-size: cover; /* Ensure the image covers the container */
  background-position: center; /* Center the background image */
  border-radius: 1vh; /* Optional: Add border radius for each service */
}

/* Media query for even smaller screens */
@media (max-width: 480px) {
  .service-container {
    width: 100%; /* Full width */
    height: 20vh; /* Further reduce height for compact screens */
  }
}

/* Default styles for service description */
.service-description {
  width: 45%; /* Default width */
  padding: 20px; /* Default padding */
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background for text */
  border-radius: 8px; /* Border radius for each service */
}

.service-description h3 {
  /* color: #333;   */
  font-size: 30px;
  line-height: 48px;
  font-family: "CircularStdBold", Arial, sans-serif;
}

.service-description p {
  font-size: 20px;
  line-height: 28px;
  font-family: "CircularXX-Regular";
  color: #666;
}

/* Media query for tablets and smaller devices */
@media (max-width: 768px) {
  .service-description {
    width: 60%; /* Increase width for smaller screens */
    padding: 15px; /* Decrease padding to avoid overflow */
  }

  .service-description h3 {
    font-size: 20px; /* Reduce font size for smaller screens */
    line-height: 48px;
    font-family: "CircularStdBold", Arial, sans-serif;
  }

  .service-description p {
    font-size: 12px; /* Reduce font size for better readability */
    line-height: 28px;
    font-family: "CircularXX-Regular";
    color: #666;
  }
}

/* Media query for even smaller screens (e.g., mobile devices) */
@media (max-width: 480px) {
  .service-description {
    width: 100%; /* Full width on smallest screens */
    padding: 10px; /* Further reduce padding */
  }

  .service-description h3 {
    font-size: 20px; /* Further reduce font size */
    line-height: 48px;
    font-family: "CircularStdBold", Arial, sans-serif;
  }

  .service-description p {
    font-size: 12px; /* Adjust font size to fit smaller screens */
    line-height: 28px;
    font-family: "CircularXX-Regular";
    color: #666;
  }
}

.service-container:hover {
  box-shadow: 0 10px 20px rgba(94, 255, 0, 0.356); 
  transform: scale(1.05); /* Slight enlargement on hover */
  filter: brightness(1.1); /* Increase brightness to give a "highlighted" look */
}