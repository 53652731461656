/* Import Animate.css for animations */
@import 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css';

/* Section styling */
#software-development-expertise {
    padding: 50px;
    background-color: #f8f9fa;
}

#software-development-expertise h1 {
    font-size: 2em;
    color: #007bff;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-top: 4%;
}

#software-development-expertise h2 {
    font-size: 2em;
    color: #343a40;
    margin-top: 40px;
}

#software-development-expertise h3 {
    font-size: 1.75em;
    color: #b5d451;
    margin-top: 30px;
}

#software-development-expertise p {
    font-size: 1.1em;
    line-height: 1.6;
}

#software-development-expertise ul {
    margin-top: 20px;
    padding-left: 0;
}

/* List styling */
#software-development-expertise .list-group-item {
    background-color: transparent;
    border: none;
    font-size: 1.1em;
    color: #495057;
    transition: all 0.3s ease;
}

#software-development-expertise .list-group-item:hover {
    background-color: #007bff;
    color: white;
}

#software-development-expertise .list-group-item::before {
    content: '•';
    color: #007bff;
    margin-right: 10px;
    font-size: 1.2em;
}

/* Image Styling */
#software-development-expertise img {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#software-development-expertise img:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    #software-development-expertise {
        padding: 20px;
    }

    #software-development-expertise h1 {
        font-size: 2em;
        margin-top: 12%;
    }

    #software-development-expertise h2 {
        font-size: 1.75em;
    }

    #software-development-expertise h3 {
        font-size: 1.5em;
    }
}
