/* AI.css */
.ai-container {
    background-color: #1c1f26;
    color: #f8f9fa;
    padding: 60px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.parallax-background {
    /* background-image: url('../assets/img/ai-background.jpg');  */
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    width: 100%;
    /* height: 400px; */
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.8;
}

.ai-content {
    position: relative;
    z-index: 1;
    max-width: 80%;
    margin: 0 auto;
}

.section-title1 {
    font-size: 3rem;
    color: #00d4ff;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    animation: fadeInDown 1.2s ease-in-out;
    padding: 1%;
}

.section-text {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #f8f9fa;
    line-height: 1.7;
    max-width: 50%;
    margin: 0 auto;
}

.collapsed-extras {
    margin-top: 20px;
}

.ai-feature-cards {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    flex-wrap: wrap;
}

.ai-feature-card {
    background-color: #343a40;
    border-radius: 10px;
    padding: 20px;
    width: 30vh;
    text-align: center;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease;
    margin: 10px; /* Add margin for responsive spacing */
}

.ai-feature-card:hover {
    transform: translateY(-10px);
}

.ai-icon {
    width: 60px;
    margin-bottom: 15px;
}

.cta-button {
    display: inline-block;
    background-color: #00d4ff;
    color: white;
    padding: 12px 30px;
    font-size: 1.1rem;
    border-radius: 30px;
    margin-top: 30px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
    background-color: #007bff;
    transform: scale(1.05);
}

.image-carousel {
    overflow: hidden;
    margin-top: 40px;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 50px;
}

.carousel-image {
    width: 30%;
    height: 18rem;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 20px;
    transition: transform 0.3s ease;
}

.carousel-image:hover {
    transform: scale(1.05);
}

.expanded-content {
    margin-top: 30px;
    animation: fadeInUp 1s ease-in-out;
}

.ai-use-cases {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap;
}

.use-case {
    background-color: #2c3036;
    border-radius: 10px;
    padding: 20px;
    width: 30%;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
    margin: 10px; /* Add margin for responsiveness */
}

.use-case:hover {
    transform: translateY(-10px);
}

.ai-image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 50px;
}

.grid-image {
    width: 100%;
    height: 18rem;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease;
}

.grid-image:hover {
    transform: scale(1.05);
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 1024px) {
    .ai-feature-cards,
    .ai-use-cases {
        flex-direction: column;
        align-items: center;
    }

    .use-case {
        width: 80%;
        margin-bottom: 20px;
    }

    .ai-image-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}


/* Adjust grid layout for mobile view */
@media (max-width: 1024px) {
    .ai-feature-cards,
    .ai-use-cases {
        flex-direction: column;
        align-items: center;
    }

    .use-case {
        width: 80%;
        margin-bottom: 20px;
    }

    /* Single-column layout for images on mobile */
    .ai-image-grid {
        grid-template-columns: repeat(1, 1fr); /* Display images in a single column */
    }

    /* Ensure responsive image display */
    .carousel-image,
    .grid-image {
        width: 100%;
        height: auto;
    }
}
