.contact-links {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    z-index: 1000;
  }
  
  .contact-icon {
    background: linear-gradient(145deg, #fff, #f0f0f0); /* Soft gradient background */
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
    cursor: pointer;
  }
  
  .contact-icon:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    background: linear-gradient(145deg, #f0f0f0, #fff); /* Reverse the gradient on hover */
  }
  
  .linkedin {
    color: #0077b5; /* LinkedIn blue */
  }
  
  .whatsapp {
    color: #25d366; /* WhatsApp green */
  }
  
  .facebook {
    color: #3b5998; /* Facebook blue */
  }
  
  /* Icon-specific hover effects */
  .linkedin:hover {
    color: #005f8d;
  }
  
  .whatsapp:hover {
    color: #1ebd56;
  }
  
  .facebook:hover {
    color: #2d4373;
  }
  