.data-integration {
  padding: 2rem 2rem;
  max-width: 80%;
  margin: auto;
  background: linear-gradient(to right, #f4f9f6, #ffffff);
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin-top: 4%;
}

.data-integration-intro {
  text-align: center;
  margin-bottom: 3rem;
}

.data-integration-intro h2 {
  color: #007BFF;
  margin: 0;
  margin-bottom: 0.5rem;
}

.data-integration-intro p {
  font-size: 1.25rem;
  color: #666;
  max-width: 100%;
  margin: 0 auto;
}

.data-integration-details {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.data-integration-point {
  flex: 1 1 calc(50% - 2rem); /* Two columns with gap */
  padding: 2rem;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.data-integration-point:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.data-integration-point h3 {
  color: #343a40;
  margin-bottom: 1rem;
}

.data-integration-point p {
  font-size: 1rem;
  color: #555;
}

@media (max-width: 768px) {
  .data-integration-point {
    flex: 1 1 100%; /* Single column on mobile */
  }
  .data-integration {
    padding: 4rem 2rem;
  }
}
