.data-analysis-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 80%;
    margin: 0 auto;
    margin-top: 4%;
}

.data-analysis-header {
    text-align: center;
    /* margin-bottom: 30px; */
    margin-top: 5%;
}

.header-title {
    font-size: 2.5em;
    color: #007BFF;
    margin: 0;
}

.header-subtitle {
    font-size: 1.2em;
    color: #666;
    margin-top: 10px;
}

.data-analysis-overview {
    margin: 30px 0;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.data-analysis-overview h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 15px;
}

.data-analysis-overview p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
}

.data-analysis-capabilities {
    margin: 30px 0;
}

.section-title {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
    font-weight: 600;
    border-bottom: 3px solid #007BFF;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.capabilities-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.capability-card {
    flex: 1 1 calc(33% - 20px);
    padding: 20px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.capability-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.capability-card h3 {
    font-size: 1.5em;
    margin: 0 0 10px;
}

.capability-card p {
    font-size: 1em;
    color: #555;
}

.image-text-block {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.content-image {
    width: 50%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.left {
    margin-right: 20px;
}

.right {
    margin-left: 20px;
}

.text-block {
    flex: 1;
}

.text-block h3 {
    font-size: 1.8em;
    margin-top: 0;
    color: #007BFF;
}

.text-block p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
}

.integration-section {
    margin: 30px 0;
}

.integration-points {
    display: flex;
    gap: 20px;
}

.integration-point {
    flex: 1;
    padding: 20px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.integration-point h3 {
    font-size: 1.5em;
    margin: 10px 0;
}

.integration-point p {
    font-size: 1em;
    color: #555;
}

.integration-image {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
}

.learn-more-button {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    font-size: 1em;
    cursor: pointer;
    margin: 20px 0;
}

.learn-more-button:hover {
    background-color: #0056b3;
}

.expanded-explanation {
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f0f8ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.expanded-explanation h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 15px;
}

.expanded-explanation p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
}

/* General Styles for Mobile Devices */
@media (max-width: 768px) {
    .data-analysis-container {
        padding: 10px;
    }

    .header-title {
        font-size: 1.8em;
        margin-top: 16%;
    }

    .header-subtitle {
        font-size: 1em;
    }

    .data-analysis-overview {
        padding: 15px;
    }

    .data-analysis-overview h2 {
        font-size: 1.5em;
    }

    .data-analysis-overview p {
        font-size: 1em;
    }

    .capabilities-cards {
        flex-direction: column;
    }

    .capability-card {
        flex: 1 1 100%;
        margin-bottom: 20px;
    }

    .section-title {
        font-size: 1.8em;
    }

    .capability-card h3 {
        font-size: 1.2em;
    }

    .capability-card p {
        font-size: 0.9em;
    }

    .image-text-block {
        flex-direction: column;
        text-align: center;
    }

    .content-image {
        width: 100%;
        margin: 0 0 20px;
    }

    .text-block {
        text-align: center;
    }

    .text-block h3 {
        font-size: 1.5em;
    }

    .text-block p {
        font-size: 1em;
    }

    .integration-section {
        padding: 10px;
    }

    .integration-points {
        flex-direction: column;
    }

    .integration-point {
        margin-bottom: 20px;
    }

    .integration-point h3 {
        font-size: 1.2em;
    }

    .integration-point p {
        font-size: 0.9em;
    }

    .learn-more-button {
        width: 100%;
        padding: 12px 0;
        font-size: 1em;
    }

    .expanded-explanation h2 {
        font-size: 1.8em;
    }

    .expanded-explanation p {
        font-size: 1em;
    }
}

/* For smaller mobile screens */
@media (max-width: 480px) {
    .header-title {
        font-size: 1.5em;
        margin-top: 16%;
    }

    .header-subtitle {
        font-size: 0.9em;
    }

    .section-title {
        font-size: 1.5em;
    }

    .capability-card h3 {
        font-size: 1em;
    }

    .capability-card p {
        font-size: 0.8em;
    }

    .image-text-block .content-image {
        width: 100%;
    }

    .text-block h3 {
        font-size: 1.2em;
    }

    .text-block p {
        font-size: 0.9em;
    }

    .integration-point h3 {
        font-size: 1em;
    }

    .integration-point p {
        font-size: 0.8em;
    }
}

.cap-card {
    --white: hsl(0, 0%, 100%);
    --black: hsl(240, 15%, 9%);
    --paragraph: hsl(0, 0%, 83%);
    --line: hsl(240, 9%, 17%);
    --primary: hsl(189, 92%, 58%);
  
    position: relative;
  
    display: flex;
    flex-direction: column;
    gap: 1rem;
  
    padding: 1rem;
    width: 23rem;
    height: 10rem;
    background-color: #b7d552 ;
    background-image: linear-gradient(43deg, #b7d552  0%, hsl(188, 94%, 13%) 46%, #5f7082 100%);
  
    border-radius: 1rem;
    box-shadow: 0px -16px 24px 0px rgba(255, 255, 255, 0.25) inset;
  }
  
  .cap-card .card__border {
    overflow: hidden;
    pointer-events: none;
  
    position: absolute;
    z-index: -10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background-image: linear-gradient(
      0deg,
      hsl(0, 0%, 100%) -50%,
      hsl(0, 0%, 40%) 100%
    );
  
    border-radius: 1rem;
  }
  
  .cap-card .card__border::before {
    content: "";
    pointer-events: none;
  
    position: fixed;
    z-index: 200;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%), rotate(0deg);
    transform-origin: left;
  
    width: 200%;
    height: 10rem;
    background-image: linear-gradient(
      0deg,
      hsla(0, 0%, 100%, 0) 0%,
      hsl(189, 100%, 50%) 40%,
      hsl(189, 100%, 50%) 60%,
      hsla(0, 0%, 40%, 0) 100%
    );
  
    animation: rotate 8s linear infinite;
  }
  
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
  
  .cap-card .card_title__container .card_title {
    font-size: 1.5rem;
    color: var(--white);
  }
  
  .cap-card .card_title__container .card_paragraph {
    font-size: 1rem;
    color: var(--paragraph);
  }
  