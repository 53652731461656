/* Main container */
.overview-container {
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  margin: 1% auto;
  font-family: 'Roboto', sans-serif;
  line-height: 1.8;
  color: #333;
  margin-top: 4%;
}

/* Header */
.overview-header {
  text-align: center;
  /* margin-bottom: 40px; */
}

.overview-title {
  font-size: 42px;
  color: #2a2a2a;
  margin-bottom: 10px;
}

.overview-subtitle {
  font-size: 20px;
  color: #666;
}

/* Section titles */
.section-overview-title {
  font-weight: 700;
  color: #0073e6;
  margin-bottom: 20px;
  text-align: center;
}

/* Paragraph styling */
.overview-paragraph {
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.6;
}


/* Services List */
.overview-services-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
  grid-template-columns: repeat(2, 1fr);
  margin: 1%;
  gap: 20px;
}

/* Service List Items */
.overview-services-list li {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  font-size: 18px;
  color: black;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 2.1);
  display: flex;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  text-align: center;
  background: transparent;
}

.overview-services-list li:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

/* Custom Bullet */
.overview-services-list li::before {
  /* content: '✔';  */
  color: #3498db;
  font-size: 24px;
  margin-right: 10px;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

/* Conclusion Section */
.overview-conclusion {
  margin-top: 30px;
}

/* Responsive design */
@media (max-width: 768px) {
  .overview-container {
      /* padding: 20px; */
      max-width: 100%;
      margin-top: 14%;
  }

  .overview-title {
      font-size: 32px;
  }

  /* .section-title {
      font-size: 24px;
  } */

  .overview-paragraph, .overview-services-list li {
      font-size: 16px;
  }
  .overview-services-list li {
    text-align: left;
}

}


@media (max-width: 480px) {
  .overview-services-list {
    grid-template-columns: 1fr; /* 1 item per row for small screens */
  }
}

/* .services-overview-section {
  position: relative;
  padding: 60px 20px;
  text-align: center;
  margin-top: 60px;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
}

.services-overview-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../assets/img/body.png') no-repeat center center/cover;
  background-size: cover;
  filter: blur(8px); 
  z-index: -1;
  background-color: rgba(228, 222, 222, 0.877);
  opacity: 1;
} */

.banner11 {
    position: relative;
    text-align: center;
    margin-top: 60px;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.banner11 img {
    width: 100%;
    height: 100%; /* Maintain the aspect ratio */
    object-fit: cover; /* Cover the entire area of the div */
    object-position: center; /* Position the image center when cropping */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

#ourCore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(228, 222, 222, 0.8); /* Slight overlay to improve text visibility */
    padding: 30px;
    box-sizing: border-box;
}



/* New section for 'Our Approach' */
.approach-section {
  margin-top: 30px;
  padding: 20px;
  background-color: #e0f7fa;
  border-left: 5px solid #00796b;
  border-radius: 4px;
}

.section-approach-title {
  font-size: 22px;
  color: #00796b;
  margin-bottom: 10px;
}

.approach-paragraph {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

/* New section for 'Company Values' */
.values-section {
  margin-top: 30px;
  padding: 20px;
  background-color: #ffebee;
  border-left: 5px solid #d32f2f;
  border-radius: 4px;
}

.section-values-title {
  font-size: 22px;
  color: #d32f2f;
  margin-bottom: 10px;
}

.values-paragraph {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

/* .overview-conclusion {
  margin-top: 30px;
  padding: 20px;
  background-color: #f1f8e9;
  border-left: 5px solid #0073e6;
  border-right: 5px solid #0073e6;
  border-radius: 4px;
} */