/* src/components/CookiePreferencesModal.css */

.cookie-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
}

.cookie-modal {
    background-color: #f9f9f9;
    padding: 25px;
    max-width: 450px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
    animation: slideIn 0.4s ease-in-out;
    position: relative;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-30px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.cookie-modal h2 {
    margin-bottom: 20px;
    font-size: 1.75rem;
    text-align: center;
    color: #333;
}

.form-group {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    text-align: justify;
}

.switch-label{
    flex-grow: 1;
    cursor: pointer;
    font-size: 1rem;
    color: #333;
    display: flex;
    justify-content: space-between;
}

input[type='checkbox'] {
    margin-right: 10px;
}

.modal-buttons {
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
}

.btn-save,
#close {
    flex: 1;
    border: none;
    padding: 12px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    width: 100%; /* Make buttons full width */
    margin-bottom: 1%;
}

.btn-save {
    background-color: #047cfc; /* Matching button background color */
    color: white;
    border: 2px solid #047cfc; /* Border color same as background */
}

.btn-save:hover {
    background-color: #036bbf; /* Slightly darker soft blue on hover */
}

#close {
    background-color: white;
    color: #047cfc; /* Text color to match the border */
    border: 2px solid #047cfc; /* Same border as first button */
}

#close:hover {
    background-color: #f3f3f3; /* Light background on hover */
}

/* Ensure no pseudo-elements are adding content */
#close::before,
#close::after {
    display: none !important;
    content: none !important;
    transform: none !important;
}

#close:hover {
    background-color: #f0f8ff; /* Light blue background on hover */
}

/* Responsive adjustments */
@media (max-width: 500px) {
    .cookie-modal {
        padding: 15px;
    }

    .modal-buttons {
        flex-direction: column;
    }

    .btn-save,
    #close {
        width: 100%;
        margin-bottom: 10px;
    }

    #close {
        margin-bottom: 0;
    }
}

/* Custom switch container */
.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    margin-left: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}


.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #047cfc;
}

input:checked+.slider:before {
    transform: translateX(14px);
}

.slider.round {
    border-radius: 34px;
}
.cookie-description {
    font-size: 0.9rem;
    color: #666;
    margin-top: 5px;
    margin-bottom: 20px;
    line-height: 1.5;
    text-align: justify;
}
.always-active {
    font-weight: bold;
    color: #047cfc;
    margin-left: 10px;
}
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    background: none;
    border: none;
    color: #000;
    cursor: pointer;
    padding: 5px;
}

.close-btn:hover {
    background-color: #047cfc;
    color: white;
}