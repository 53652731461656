/* Contact.css */
.contact-section {
  padding: 0px 0;
  text-align: center;
}

.contact-content-new {
  /* max-width: 600px; */
  margin: 0 auto;
  width: 67%;
}

.contact-content-new h2 {
  color: white;
  font-size: 28px;
  margin-bottom: 40px;
}

.contact-content-new p {
  background-color: aliceblue;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input,
textarea {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
}

button {
  /* width: 20vh; */
  padding: 10px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
}

button:hover {
  background-color: #555;
}
/* MyComponent.css */

.button {
  border-radius: 4px;
  background-color: #333;
  border: none;
  color: #FFFFFF;
  text-align: center;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}
.contact-content-new {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.contact-content-new h2 {
  text-align: center;
  margin-bottom: 20px;
}

.contact-content-new form {
  margin-bottom: 20px;
}

.contact-content-new p {
  text-align: center;
  margin-top: 20px;
}

.contact-content-new li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.contact-content-new li a {
  text-decoration: none;
  color: blue;
}
