/* Consulting.css */
#consulting {
  padding: 1%;
  background-color: #f4f7fb; /* Light, clean background */
  text-align: center;
  margin-top: 4%;
}

#consulting h2 {
  /* font-size: 2.5em; */
  color: #007BFF;
  margin: 0;
  margin-bottom: 0.5rem;
  margin-top: 3%;
}

#consulting .intro-text {
  /* font-size: 1.2rem; */
  line-height: 1.7;
  color: #555;
  max-width: 80%;
  margin: 0 auto 30px auto;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-card-new {
  background-color: #fff;
  border-radius: 12px;
  width: 30%;
  margin: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
  animation: fadeInUp 1.2s ease;
  overflow: hidden; /* Ensure nothing overflows */
  display: flex;
  flex-direction: column;
}

.service-card-new h3 {
  font-family: Houschka-medium;
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: .5rem;
}

.service-card-new p {
  font-size: 1rem;
  color: white;
  line-height: 1.6;
}

/* .service-card-new:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
} */

.cta-text {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 30px auto 20px auto;
  color: #444;
}

.cta-button {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  padding: 12px 30px;
  font-size: 1rem;
  border-radius: 50px;
  text-decoration: none;
  margin-top: 20px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

/* Responsive */
@media (max-width: 1024px) {
  .service-card-new {
    width: 45%;
  }
  
}

@media (max-width: 768px) {
  .service-card-new {
    width: 100%;
    margin: 15px 0;
  }

  .intro-text, .cta-text {
    font-size: 1rem;
  }

  #consulting h2 {
    font-size: 2.2rem;
    margin-top: 18%;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}
.card-content {
  position: relative;
  height: 100%; /* Ensure card content fills the entire card */
  display: flex;
  flex-direction: column;
}

.card-content img {
  width: 100%;
  height: 250px; /* Adjust the image height to maintain consistency */
  object-fit: cover; /* Ensures the image fits nicely inside the card */
  transition: transform 0.3s ease;
}

.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Start with a transparent background */
  color: white; /* Text color */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 1; /* Make the heading always visible */
  transition: background 0.3s ease; /* Smooth transition for background */
  padding: 20px;
  text-align: center;
}

.text-overlay h3 {
  opacity: 1; /* Make the heading always visible */
  margin-bottom: 10px; /* Space between title and description */
}

.text-overlay p {
  opacity: 0; /* Hide the description by default */
  transition: opacity 0.3s ease; /* Smooth transition for description */
}

.service-card-new:hover .text-overlay {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background on hover */
}

.service-card-new:hover .text-overlay p {
  opacity: 1; /* Show the description on hover */
}

.service-card-new:hover img {
  transform: scale(1.05); /* Slight zoom effect on hover */
}
@media (max-width: 768px) {
  .text-overlay p {
    opacity: 1; /* Show the description by default on mobile */
  }
}
@media (max-width: 1024px) {
  .text-overlay p {
    opacity: 1; /* Show the description by default on mobile */
  }
}
@media (max-width: 468px) {
  .text-overlay p {
    opacity: 1; /* Show the description by default on mobile */
  }
}