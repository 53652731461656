/* General Styles */
.dashboard-container {
    padding: 1%;
    background-color: #f4f7fb; /* Light, clean background */
    text-align: center;
    margin-top: 4%;
}

.dashboard-header {
    /* margin-bottom: 60px; */
}

.bydefault-title{
    font-size: 2rem;
    /* font-weight: bold; */
    /* color: #007bff;  */
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
}

/* Heading Styles */
.section-title {
    font-size: 1.8rem;
    font-weight: 700;
    color: #212529;
    text-transform: uppercase;
    letter-spacing: 1px;
    /* position: relative; */
    padding-bottom: 10px;
    margin-bottom: 30px;
}

.section-title::after {
    content: "";
    display: block;
    width: 60px;
    height: 4px;
    /* background-color: #007bff; */
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.section-dash-text {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #495057;
    line-height: 1.7;
    max-width: 90%;
    margin: 0 auto;
}

/* Intro Section for AI Integration */
.intro-section {
    margin-top: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    /* text-align: left; */
    max-width: 90%;
    margin: 20px auto;
}

.intro-section p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
}

.intro-key-benefits {
    font-size: 1.5rem;
    color: #333;
    margin-top: 20px;
}

.benefits-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.benefit-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.benefit-card:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.benefit-title {
    font-size: 1.5rem;
    color: #343a40;
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: capitalize;
}

.benefit-description {
    font-size: 1rem;
    color: #6c757d;
    line-height: 1.5;
}

/* Learn More Button */
.learn-more-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.learn-more-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

/* Expanded Explanation Section */
/* .expanded-explanation {
    margin-top: 40px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    margin: 20px auto;
    display: none;
} */

.expanded-explanation.show {
    display: block; /* Show when expanded */
}

.expanded-title {
    font-size: 2rem;
    font-weight: bold;
    color: #007bff; /* Bright color for emphasis */
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
}

.case-study-title {
    font-size: 1.5rem;
    color: #333;
    margin-top: 20px;
    font-weight: 600;
}

.case-study-title + p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
}

/* Main Dashboard Section */
.dashboard-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.dashboard-main-image {
    width: 100%;
    max-width: 500px;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.dashboard-main-info {
    max-width: 90%;
    padding: 20px;
    text-align: center;
}

.dashboard-main-title {
    font-size: 2rem;
    color: #212529;
    margin-bottom: 15px;
    font-weight: 700;
    text-transform: uppercase;
}

.dashboard-main-description {
    font-size: 1.2rem;
    color: #495057;
    line-height: 1.7;
}

/* Feature Cards */
.dashboard-features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.feature-dash-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.feature-dash-image {
    width: 100%;
    max-width: 100%;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    height: 50%;
}

.feature-dash-title {
    font-size: 1.5rem;
    color: #212529;
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: capitalize;
}

.feature-dash-description {
    font-size: 1rem;
    color: #495057;
    line-height: 1.6;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .dashboard-main {
        flex-direction: column;
        align-items: center;
    }

    .dashboard-main-info {
        max-width: 100%;
        padding: 10px;
    }

    .dashboard-main-title {
        font-size: 1.8rem;
    }

    .dashboard-main-description {
        font-size: 1rem;
    }
}

@media (max-width: 992px) {
    .section-title {
        font-size: 2.5rem;
        margin-top: 5%;
    }

    .section-dash-text {
        font-size: 1.1rem;
    }

    .benefit-card {
        padding: 15px;
    }

    .benefit-title {
        font-size: 1.3rem;
    }

    .benefit-description {
        font-size: 0.9rem;
    }

    .learn-more-button {
        font-size: 1rem;
        padding: 8px 16px;
    }
}

@media (max-width: 768px) {
    .intro-section {
        padding: 15px;
    }

    .intro-key-benefits {
        font-size: 1.3rem;
    }

    .benefits-container {
        grid-template-columns: 1fr;
    }

    .benefit-title {
        font-size: 1.2rem;
    }

    .benefit-description {
        font-size: 0.8rem;
    }

    .dashboard-main-title {
        font-size: 1.6rem;
    }

    .dashboard-main-description {
        font-size: 1rem;
    }

    .feature-dash-card {
        padding: 15px;
    }

    .feature-dash-title {
        font-size: 1.3rem;
    }

    .feature-dash-description {
        font-size: 0.9rem;
    }
}

@media (max-width: 576px) {
    .section-title {
        font-size: 2rem;
        margin-top: 14%;
    }

    .section-dash-text {
        font-size: 1rem;
    }

    .dashboard-main-image {
        max-width: 100%;
    }

    .dashboard-main-info {
        padding: 10px;
    }

    .dashboard-main-title {
        font-size: 1.4rem;
    }

    .dashboard-main-description {
        font-size: 0.9rem;
    }

    .feature-dash-image {
        max-width: 150px;
    }

    .feature-dash-title {
        font-size: 1.2rem;
    }

    .feature-dash-description {
        font-size: 0.8rem;
    }
}

