/* Basic Navbar Styles */
.navbar {
  background-color: #ffffff; /* White background for the navbar */
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  top: 0; /* Ensure it's at the top */
  left: 0;
  width: 100%; /* Make sure it spans the entire width */
  z-index: 1000; /* Ensure it stays above other content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.center-logo {
  height: 50px; /* Logo height */
  cursor: pointer;
  margin-left: 1%;
}

/* Navbar content and links */
.navbar-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 14%;
}

.nav-link {
  color: #333;
  text-decoration: none;
  margin: 0 1rem;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #007bff; /* Hover effect */
}

.nav-link.active {
  color: #007bff;
  font-weight: bold;
}

/* Dropdown menu */
.nav-dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  top: 100%;
  left: 0;
  z-index: 100;
}

.dropdown-item {
  display: block;
  padding: 0.5rem 1rem;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #007bff;
  color: #fff;
}

.nav-dropdown:hover .dropdown-menu {
  display: block;
}

/* Sub-dropdown for Cloud Computing */
.dropdown-left {
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.navbar-toggle {
  display: none;
  background-color: #007bff;
  color: #fff;
  border: none;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  width: 20%;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar-content {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }

  .navbar-content.show {
    display: flex;
  }

  .navbar-toggle {
    display: block;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
  }

  .dropdown-left {
    position: static;
    box-shadow: none;
  }

  .nav-dropdown:hover .dropdown-menu {
    display: contents;
  }
}
