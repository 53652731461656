.contribution-page {
    padding: 20px;
    max-width: 80%;
    margin: auto;
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f9;
    margin-top: 4%;
}

.info-section {
    margin-bottom: 30px;
    text-align: center;
}

.info-section h2 {
    font-size: 2rem;
    color: #3498db;
    margin-top: 4%;
}

.featured-section,
.testimonials-section,
.cta-section {
    margin-bottom: 40px;
}

.featured-content {
    display: flex;
    align-items: center;
    gap: 20px;
}

.featured-image {
    width: 100%;
    max-width: 50%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.featured-text {
    flex: 1;
}

.featured-text h3 {
    font-size: 1.5em;
    color: #3498db;
}

/* Add alternating flex direction */
.featured-section.alternate .featured-content {
    flex-direction: row-reverse; /* Reverse the order of image and text */
}

.testimonial {
    display: flex;
    align-items: center;
    gap: 20px;
}

.testimonial-image {
    width: 100%;
    max-width: 50%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.testimonial-text {
    flex: 1;
    font-style: italic;
    color: #555;
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
    .featured-content,
    .testimonial,
    .cta-content {
        flex-direction: column;
        text-align: center;
    }

    .featured-image,
    .testimonial-image,
    .cta-image,
    .donation-image {
        max-width: 100%;
    }

    h2 {
        font-size: 1.5em;
    }

    .featured-text h3,
    .cta-text h3 {
        font-size: 1.2em;
    }
}

@media (max-width: 480px) {
    h2 {
        font-size: 1.2em;
    }

    .featured-text h3,
    .cta-text h3 {
        font-size: 1em;
    }
}

#h2center{
    text-align: right;
}