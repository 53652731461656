.mission-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 20px;
  background-color: #f9fafb;
}

.mission-content {
  flex: 1;
  /* padding-right: 40px; */
}

.mission-content h2 {
  /* font-size: 36px; */
  font-weight: 700;
  color: #0073e6;
  margin-bottom: 20px;
  text-align: center;
}

.mission-content p {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 15px;
}

.mission-image {
  flex: 1;
}

.mission-image img {
  max-width: 100%;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsive styles */
@media (max-width: 768px) {
  .mission-section {
    flex-direction: column;
    text-align: center;
  }

  .mission-content {
    padding-right: 0;
    margin-bottom: 30px;
  }
}
