/* Home.css */
.hero-section {
  position: relative;
  height: 48vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 4%;
  overflow: hidden; /* Ensures video fits perfectly inside */
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire background */
  z-index: -1; /* Sends the video behind the content */
}

.hero-content {
  position: relative;
  z-index: 1; /* Ensure content stays above the video */
  text-align: center;
}

.hero-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.hero-description {
  font-size: 1.2rem;
  max-width: 700px;
  margin: 0 auto;
}


.hero-content {
  text-align: center;
  /* max-width: 50%; */
  .home-section {
    font-family: 'Poppins', sans-serif;
    color: #333;
    background-color: #f5f5f5;
  }
  
  /* Hero Section */
  .hero-section {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #00c6ff, #0073e6); /* Gradient background */
  }
  
  
  .hero-title {
    font-size: 56px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 20px;
  }
  
  .hero-description {
    font-size: 22px;
    color: #fff;
    margin-bottom: 30px;
  }
  
  .cta-button {
    background-color: #ff6347;
    border: none;
    padding: 15px 40px;
    font-size: 18px;
    font-weight: 600;
    color: white;
    cursor: pointer;
    border-radius: 50px;
    transition: background 0.3s ease, transform 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #ff4500;
    transform: translateY(-5px);
  }
  
  /* Services Section */
  .services-section {
    padding: 100px 20px;
    background-color: #f9fafb;
    text-align: center;
  }
  
  .section-title {
    font-size: 42px;
    font-weight: 700;
    color: #333;
    margin-bottom: 60px;
    position: relative;
    display: inline-block;
  }
  
  .section-title:before {
    content: '';
    position: absolute;
    width: 80px;
    height: 3px;
    background-color: #0073e6;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
    padding: 0 20px;
    margin: 1%;
  }
  
  .service-card {
    background: white;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .service-card h3 {
    font-size: 26px;
    color: #0073e6;
    margin-bottom: 20px;
  }
  
  .service-card p {
    font-size: 18px;
    color: #666;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  }
  
  /* Smooth Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Responsive Media Queries */
  @media (max-width: 768px) {
    .hero-title {
      font-size: 36px;
    }
  
    .hero-description {
      font-size: 18px;
    }
  
    .cta-button {
      font-size: 16px;
    }
  
    .service-card h3 {
      font-size: 22px;
    }
  
    .services-section {
      padding: 60px 20px;
    }
  
    .services-grid {
      grid-template-columns: 1fr;
    }
  }
  
  /* padding: 40px;
  border-radius: 10px; */
}

.hero-title {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
}

.hero-description {
  font-size: 20px;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #00c6ff;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  transition: background 0.3s ease;
}

.cta-button:hover {
  background-color: #0073e6;
}

/* Services Section */
.services-section {
  padding: 60px 20px;
  background-color: #f5f5f5;
  text-align: center;
}

.section-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 40px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin: 1%;
}

.service-card {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card h3 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #0073e6;
}

.service-card p {
  font-size: 16px;
  color: #666;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 32px;
  }

  .cta-button {
    font-size: 16px;
  }

  .service-card h3 {
    font-size: 20px;
  }
}

/* AI Section */
.ai-home-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 20px;
  background-color: #f1f7fa;
}

.ai-home-content {
  flex: 1;
  /* padding-right: 40px; */
}

.ai-home-content h2 {
  font-weight: 700;
  color: #0073e6;
  margin-bottom: 20px;
  text-align: center;
}

.ai-home-content p {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 15px;
}

.ai-home-image {
  flex: 1;
}

.ai-home-image img {
  max-width: 100%;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .ai-home-section {
    flex-direction: column;
    text-align: center;
  }

  .ai-home-content {
    padding-right: 0;
    margin-bottom: 30px;
  }

  .hero-title {
    font-size: 36px;
  }

  .hero-description {
    font-size: 18px;
  }

  .cta-button {
    font-size: 16px;
  }
}

/* Main container styles */
.tagline-banner {
  color: #666; /* White text */
  padding: 3rem; /* Base padding */
  text-align: center; /* Center align text */
}

/* Heading styles */
#h2 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #212529;
  text-transform: uppercase;
  letter-spacing: 1px;
  /* position: relative; */
  padding-bottom: 10px;
  margin-bottom: 30px;
  
}



/* Paragraph styles */
#p {
  font-size: 1.5rem; /* Base font size */
  margin-bottom: 2rem; /* Space below */
}

#p {
  font-size: clamp(1.25rem, 3vw, 2rem); /* Responsive font size */
}

/* Intro box styles */
.intro-box {
  background-color: #ffffff; /* White background */
  color: #4f46e5; /* Indigo text */
  padding: 2rem; /* Padding */
  border-radius: 0.5rem; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Shadow effect */
  margin-bottom: 2rem; /* Space below */
  width: 90%; /* Full width on small screens */
  max-width: 600px; /* Max width */
  margin-left: auto; /* Center */
  margin-right: auto; /* Center */
}

/* Intro heading styles */
.intro-box h2 {
  font-weight: bold; /* Bold font */
  margin-bottom: 1rem; /* Space below */
}

.intro-box h2 {
  font-size: clamp(1.75rem, 4vw, 2.5rem); /* Responsive font size */
}

/* Intro paragraph styles */
.intro-box p {
  font-size: 1rem; /* Base font size */
  margin-bottom: 1rem; /* Space below */
}

.intro-box p {
  font-size: clamp(0.875rem, 2.5vw, 1.25rem); /* Responsive font size */
}

/* Button styles */
#buttonAD {
  background-color: #4f46e5; /* Custom indigo */
  color: #ffffff; /* White text */
  font-weight: bold; /* Bold font */
  padding: 0.75rem 1.5rem; /* Base padding */
  border-radius: 0.25rem; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition */
  margin: 0.5rem; /* Margin around buttons */
}

/* Button hover effect */
#buttonAD:hover {
  background-color: #3730a3; /* Darker shade for hover */
}

/* Responsive button padding */
#buttonAD {
  padding: clamp(0.5rem, 1.5vw, 1rem) clamp(1rem, 2vw, 1.5rem); /* Responsive padding */
}

/* From Uiverse.io by gharsh11032000 */ 
.card {
  background-color: #b7d552;
  background-image: linear-gradient(43deg, #b7d552 0%, hsl(188, 94%, 13%) 46%, #5f7082 100%);
  border-radius: 8px;
  color: white;
  overflow: hidden;
  position: relative;
  transform-style: preserve-3d;
  perspective: 1000px;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
  cursor: pointer;
}

.card-content {
  padding: 20px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.card-content .card-title {
  font-size: 24px;
  font-weight: 700;
  color: inherit;
  text-transform: uppercase;
}

.card-content .card-para {
  color: inherit;
  opacity: 0.8;
  font-size: 14px;
}

.card:hover {
  transform: rotateY(10deg) rotateX(10deg) scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.1));
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.320, 1);
  z-index: 1;
}

.card:hover:before {
  transform: translateX(-100%);
}

.card:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.1));
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.320, 1);
  z-index: 1;
}

.card:hover:after {
  transform: translateX(100%);
}





