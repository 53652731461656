.diagram-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 60px 20px; */
    background-color:#f4f7fb;
    border-radius: 8px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
}

h1 {
    font-size: 28px; /* Title size */
    margin-bottom: 30px; /* Space below the title */
    color: #00796b; /* Title color */
    text-align: center; /* Centered title */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Title shadow */
}

/* Content section styles */
.content-section {
    display: flex;
    justify-content: space-between; /* Space between the diagram and info section */
    width: 100%; /* Full width */
    max-width: 1200px; /* Max width for the container */
}

.diagram {
    flex: 1; /* Take remaining space */
    margin-right: 20px; /* Space between diagram and info section */
    border: 1px solid #4db6ac; /* Border for the SVG area */
    border-radius: 8px; /* Rounded corners for SVG */
    background: linear-gradient(180deg, #ffffff 0%, #f1f8e9 100%); /* Gradient background */
}

.info-AgileDevOps-section {
    flex: 1; /* Take remaining space */
    max-width: 80%; /* Maximum width */
    margin: 20px 0; /* Margin for the section */
    padding: 20px; /* Padding inside the section */
    /* background-color: #ffffff;  */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    font-family: 'Arial', sans-serif; /* Font family */
}

/* Heading styles */
#h2 {
    /* font-size: 2em; */
    color: #333333; /* Dark color for visibility */
    margin-bottom: 15px; /* Space below headings */
    /* border-bottom: 2px solid #007bff; */
    padding-bottom: 5px; /* Space between text and underline */
}

#h3 {
    font-size: 1.5em; /* Size for secondary headings */
    color: #007bff; /* Blue color for subheadings */
    margin: 15px 0 10px; /* Space around subheadings */
    text-align: justify;
}

/* Paragraph styles */
#p {
    font-size: 1em; /* Base font size for paragraphs */
    line-height: 1.6; /* Increased line height for readability */
    color: #555555; /* Dark gray color for text */
    margin-bottom: 15px; /* Space below paragraphs */
    text-align: justify;
    padding: 5px;
}

/* Styles for unordered lists */
ul {
    list-style: none; /* Remove default bullets */
    padding-left: 0; /* Remove left padding */
}

/* Styles for list items */
li {
    position: relative; /* Positioning for custom bullet */
    background-color: #f0f8ff; /* Light background for list items */
    border-left: 5px solid #007bff; /* Left border for visual interest */
    padding: 12px 20px; /* Padding for list items */
    margin-bottom: 10px; /* Space between list items */
    border-radius: 5px; /* Rounded corners for list items */
    transition: background-color 0.3s; /* Smooth transition on hover */
    text-align: justify;
}

/* Hover effect for list items */
.info-AgileDevOps-section li:hover {
    background-color: #e1f5fe; /* Change background on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .info-section {
        padding: 15px; /* Adjust padding on smaller screens */
    }

    h2 {
        font-size: 1.8em; /* Smaller heading size */
    }

    h3 {
        font-size: 1.4em; /* Smaller subheading size */
    }

    p {
        font-size: 0.9em; /* Smaller paragraph size */
    }

    li {
        padding: 10px; /* Smaller padding for list items */
    }
}

@media (max-width: 480px) {
    h2 {
        font-size: 1.6em; /* Further reduce heading size */
    }

    h3 {
        font-size: 1.2em; /* Further reduce subheading size */
    }

    p {
        font-size: 0.85em; /* Smaller paragraph size */
    }

    .info-section {
        padding: 10px; /* Minimal padding on very small screens */
    }

    li {
        padding: 8px; /* Smaller padding inside list items */
    }
}

/* Base styles for the diagram container */
.devops-diagram-container,.agile-diagram-container {
    width: 100%; /* Full width container */
    max-width: 100%; /* Limit the maximum width */
    margin: 0 auto; /* Center the container */
    padding: 20px; /* Add padding for spacing */
    box-sizing: border-box;
  }
  
  /* Make the diagram image or content responsive */
  .devops-diagram-container,.agile-diagram-container img {
    width: 100%; /* Responsive image, scales with the container */
    height: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Ensure it doesn't overflow */
  }
  
  /* Responsive text and elements inside the diagram */
  .devops-diagram-container,.agile-diagram-container h1,
  .devops-diagram-container,.agile-diagram-container p {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .devops-diagram-container,.agile-diagram-container p {
    font-size: 1rem;
  }
  
  /* Mobile-first responsive adjustments */
  @media (max-width: 768px) {
    .devops-diagram-container,.agile-diagram-container {
      padding: 10px; /* Reduce padding on smaller screens */
    }
  
    .devops-diagram-container,.agile-diagram-container h1 {
      font-size: 1.5rem; /* Adjust font size */
    }
  
    .devops-diagram-container,.agile-diagram-container p {
      font-size: 0.9rem; /* Reduce text size on smaller screens */
    }
  }
  
  /* Tablet and larger screen adjustments */
  @media (min-width: 769px) and (max-width: 1200px) {
    .devops-diagram-container,.agile-diagram-container {
      padding: 15px; /* Adjust padding for medium screens */
    }
  
    .devops-diagram-container,.agile-diagram-container h1 {
      font-size: 2rem; /* Slightly larger text on tablets */
    }
  }
  