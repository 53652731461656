.gallery-slideshow-section {
  padding: 3rem 2rem;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery-content {
  text-align: center;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid with two images per row */
  gap: 1rem;
  justify-content: center;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
  border-radius: 8px; /* Optional: adds rounded corners */
}

.gallery-image:hover {
  opacity: 0.8;
  transform: scale(1.05); /* Zoom effect on hover */
}

.slideshow-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideshow-content {
  position: relative;
  display: flex;
  align-items: center;
  /* max-width: 90vw;
  max-height: 80vh; */
  /* background: #fff; */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  height: 100%;
  width: 100%;
  align-items: center;
}

.slideshow-image {
  /* max-width: 100%;
  max-height: 100%; */
  object-fit: contain;
  padding: 6%;
  align-content: center;
  width: 100%;
}

.slideshow-close,
.slideshow-prev,
.slideshow-next {
  position: absolute;
  /* top: 50%; */
  /* transform: translateY(-50%); */
  /* background: white; */
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  /* font-size: 2rem; */
  border-radius: 5px; /* Rounded corners for buttons */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease, transform 0.3s ease;
  width: 5%;
  opacity: 0.5;
}

.slideshow-close {
  right: 10px;
  top: 10px;
  margin-top: 5%;
}

.slideshow-prev {
  left: 10px;
}

.slideshow-next {
  right: 10px;
}

.slideshow-close:hover,
.slideshow-prev:hover,
.slideshow-next:hover {
  background: #f1f1f1; /* Light background on hover */
  transform: scale(1.1); /* Slight zoom effect on hover */
  color: #333;
}

.team-mem-header{
text-align: center;
}

.team-mem-header h2 {
  color: #007BFF;
  margin: 0;
  margin-bottom: 0.5rem;
}

.team-mem-header p {
  font-size: 1.2rem;
  color: #6c757d;
  margin: 0 auto;
  line-height: 1.8;
}
/* Responsive styling for smaller screens */
@media (max-width: 480px) {
  .slideshow-close {
    right: 5px;
    top: 5px;
    font-size: 1.5rem; /* Slightly smaller button for mobile screens */
  }
}